import ujs from '@rails/ujs'
ujs.start()

import './globals/jquery'

import 'bootstrap-sass' // CSS is loaded by requests/css

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
select2(window, $)

import 'bootstrap-year-calendar'
import 'bootstrap-year-calendar/css/bootstrap-year-calendar.css'

import 'bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'

import '#/layouts/js'
import '#/requests/js'
import '#/employees/css'
import '#/admin'
