$(document).on('ajax:before', '#employee-search', function() {
  $('#employee-search-results').html('<div class="text-center"><i class="fa fa-spinner fa-5x fa-spin text-muted"></i></div>')
})

$(document).on('change', '.employee_job_assignments_job select', function() {
  let supervisors = $(this).parents('.nested-fields').find('.employee_job_assignments_supervisor select')
  $.get(`/administration/jobs/${$(this).val()}/supervisors`, function(data) {
    if (data != '')
      supervisors.html(data).removeAttr('disabled').trigger('change')
    else
      supervisors.attr('disabled', 'disabled').trigger('change')
  })
})
