import '../css'

const toggleTravelNotice = function() {
  $('#travel-info').toggleClass('hidden', $('#holiday_name').val() != 'Feast of Tabernacles')
}

$(function() {
  $('#holiday_date_range').daterangepicker()
  toggleTravelNotice()
})

$(document).on('change', '#holiday_name', toggleTravelNotice)
