import '../css'

const showHideComment = function() {
  $('.form-group.approval_comment').toggleClass('hidden', !$('#approval_approved_false').is(':checked'))
}

$(function() {
  $('#request-actions select').select2({
    theme: 'bootstrap',
    placeholder: 'Start typing name...'
  })

  $('#request-actions select').each(function(ix, el) {
    $(el).val($(el).find('option[selected]').val()).trigger('change')
  })

  showHideComment()
})

$(document).on('change', '#request-actions select', function() {
  let val = $(this).val()
  let field = $(this).attr('name')
  let link = $(this).parents('.row').find('a:first-child')

  if (val != '')
    link.attr('href', link.attr('href').replace(/(\?.*|)$/, `?${field}=${val}`)).removeAttr('disabled')
  else
    link.attr('href', link.attr('href').replace(/(\?.*|)$/, '')).attr('disabled', 'disabled')
})

$(document).on('change', 'input[name="approval[approved]"]', showHideComment)
