import moment from 'moment'

class Person {
  constructor (admin, events, holidays, feastTravel, fallBreak) {
    this.admin = admin, this.events = events, this.holidays = holidays, this.feastTravel = feastTravel, this.fallBreak = fallBreak
    this.limit = 0
    this.minDate = moment().startOf('year')
    this.maxDate = moment().endOf('year').add(1, 'year')
  }

  isHalfDay(date) {
    return this.events[date] != null &&
    this.events[date].dayType != null &&
    this.events[date].dayType.slice(0, 8) == 'half-day'
  }

  dayAmount(date) {
    return this.isHalfDay(date) ? 0.5 : 1
  }

  yesterday(date) { return moment(date).subtract(1, 'day').format('YYYY-MM-DD') }
  tomorrow(date)  { return moment(date).add     (1, 'day').format('YYYY-MM-DD') }

  halfDayType(date) {
    if (this.events[this.yesterday(date)] == null && this.events[this.tomorrow(date)] != null)
      return 'half-day-start'
    else
      return 'half-day-end'
  }

  toggleDay(date) {
    this.events[date].dayType = this.events[date].dayType == 'full-day' ? this.halfDayType(date) : 'full-day'
  }

  correctHalfDays(date) {
    var evs = {}
    evs[date] = this.events[date];

    [this.yesterday(date), this.tomorrow(date)].forEach(d => {
      if (this.isHalfDay(d))      this.events[d].dayType = this.halfDayType(d)
      if (this.events[d] != null) evs[d] = this.events[d]
    })

    return evs
  }

  description(events) {
    var breakdown = this.daysRequested(events)
    var description = `${breakdown['total']} day${breakdown['total'] != 1 ? 's' : ''} requested`

    // Request breakdown
    if (breakdown['total'] > 0) {
      var parts = []
      if (breakdown['paid'] > 0)    parts.push(`${breakdown['paid']} paid`)
      if (breakdown['unpaid'] > 0)  parts.push(`${breakdown['unpaid']} unpaid`)
      if (breakdown['holiday'] > 0) parts.push(`${breakdown['holiday']} office closed`)
      if (breakdown['travel'] > 0)  parts.push(`${breakdown['travel']} Feast travel`)
      if (breakdown['break'] > 0)   parts.push(`${breakdown['break']} Fall Break`)
      description += ` (${parts.join(', ')})`
    }

    return description
  }

  intlFeast() {
    return Object.values(this.events).reduce(((result, ev) => result || ev.intlFeastTravel), false) ||
    (!$('#feast-travel').hasClass('hidden') && $('#feast-travel input:checked').val() == 'true')
  }

  intlTravelDay(date) {
    return this.feastTravel.includes(date) && this.intlFeast()
  }
}

class Employee extends Person {
  constructor(...args) {
    super(...args)
    this.limit = 6
  }

  year(date) {
    return moment(date).year()
  }

  quota(date) {
    var year = typeof date == 'number' ? date : this.year(date)
    var q = this.limit
    $.each(this.events, (d, ev) => {
      if (ev.paidType == 'paid' && this.year(d) == year && !(this.holidays.includes(d) || this.intlTravelDay(d)))
        q -= this.dayAmount(d)
    })
    return q
  }

  clickDay(date) {
    if (this.events[date] != null)
      delete this.events[date]
    else {
      this.events[date] = { dayType: 'full-day', paidType: 'paid' }
      if (this.quota(date) < 0 && !this.admin)
        this.events[date].paidType = 'unpaid'
    }

    return this.correctHalfDays(date)
  }

  togglePaid(date) {
    this.events[date].paidType = { paid: 'unpaid', unpaid: 'paid' }[this.events[date].paidType]
    if (this.quota(date) < 0)
      this.events[date].paidType = 'unpaid'

    return this.events[date]
  }

  toggleDay(date) {
    super.toggleDay(date)
    if (this.quota(date) < 0)
      this.events[date].paidType = 'unpaid'

    return this.events[date]
  }

  canSwitchToPaid(date) {
    if (this.events[date].paidType == 'paid')
      return true

    return this.quota(date) - this.dayAmount(date) >= 0
  }

  remaining(year) {
    return `${Math.max(0, this.quota(year))} paid days remaining for ${year}`
  }

  daysRequested(events) {
    var sums = {
      paid: 0,
      unpaid: 0,
      holiday: 0,
      travel: 0,
      break: 0,
      total: 0
    }

    $.each(events, (date, ev) => {
      var amount = this.dayAmount(date)
      sums['total'] += amount

      if (this.holidays.includes(date))
        sums['holiday'] += amount
      else if (this.intlTravelDay(date))
        sums['travel'] += amount
      else
        sums[ev.paidType] += amount
    })

    return sums
  }
}

class HourlyEmployee extends Employee {
  quota(date) {
    var year = typeof date == 'number' ? date : this.year(date)
    var q = this.limit
    $.each(this.events, (d, ev) => {
      if (ev.paidType == 'paid' && this.year(d) == year)
        q -= this.dayAmount(d)
    })

    return q
  }

  clickDay(date) {
    if (this.events[date] != null)
      delete this.events[date]
    else {
      this.events[date] = { dayType: 'full-day', paidType: 'paid' }
      if (this.quota(date) < 0 && !this.admin)
        this.events[date].paidType = 'unpaid'
    }

    return this.correctHalfDays(date)
  }

  daysRequested(events) {
    var sums = {
      paid: 0,
      unpaid: 0,
      holiday: 0,
      travel: 0,
      break: 0,
      total: 0
    }

    $.each(events, (date, ev) => {
      var amount = this.dayAmount(date)
      sums['total'] += amount
      sums[ev.paidType] += amount
    })

    return sums
  }
}

class Student extends Person {
  constructor(...args) {
    super(...args)
    this.limit = 10
    this.minDate = moment().startOf('year').subtract(1, 'year')

    this.fallBreakExcluded = []

    $.each(this.events, (date, obj) => {
      if (obj.fallBreakExclude)
        this.fallBreakExcluded.push(date)
    })
  }

  year(date) {
    var d = moment(date)
    if (d.isSameOrAfter(`${d.year()}-08-01`)) // New school year starts August 1st for time off purposes
      return d.year() + 1

    return d.year()
  }

  quota(date) {
    var year = typeof date == 'number' ? date : this.year(date)

    var q = this.limit
    var h = this.holidays.filter(d => !this.fallBreak.includes(d))
    var ex = this.allFallBreakExcluded(year)

    $.each(this.events, d => {
      if (this.year(d) == year && !(h.includes(d) || ex.includes(d)))
        q -= this.dayAmount(d)
    })

    return q
  }

  clickDay(date) {
    if (this.events[date] != null)
      delete this.events[date]
    else if (this.quota(date) > 0 || this.admin) {
      this.events[date] = { dayType: 'full-day', paidType: 'unpaid' }
      if (this.quota < 0)
        this.events[date].dayType = this.halfDayType(date)
    }

    return this.correctHalfDays(date)
  }

  togglePaid(date) {
    return this.events[date]
  }

  toggleDay(date) {
    if (this.isHalfDay(date) && this.quota == 0)
      return this.events[date]

    super.toggleDay(date)
    return this.events[date]
  }

  canSwitchToPaid() {
    return false
  }

  remaining(year) {
    var remaining = []
    for (let y of [year, year + 1]) {
      remaining.push(`${Math.max(0, this.quota(y))} days remaining for ${y-1}-${y}`)
    }

    return remaining.join('<br>')
  }

  // Past requests (this.fallBreakExcluded) + new request
  allFallBreakExcluded(year) {
    var excluded = this.fallBreakExcluded.filter(d => this.year(d) == year)
    var thisBreak = this.fallBreak.filter(d => this.year(d) == year)

    $.each(this.events, (date, event) => {
      if (!event.new || excluded.length >= 14 || excluded.includes(date))
        return

      if (thisBreak.includes(date))
        excluded.push(date)
    })

    return excluded
  }

  daysRequested(events) {
    var sums = {
      paid: 0,
      unpaid: 0,
      holiday: 0,
      travel: 0,
      break: 0,
      total: 0
    }

    // Ignore office-closed days during fall break
    var holidays = this.holidays.filter(d => !this.fallBreak.includes(d))

    // If request crosses a school year boundary it won't overlap with Fall Break
    var year = this.year(Object.keys(events)[0])
    var fallBreak = this.allFallBreakExcluded(year)

    $.each(events, (date, ev) => {
      var amount = this.dayAmount(date)
      sums['total'] += amount

      if (fallBreak.includes(date))
        sums['break'] += amount
      else if (holidays.includes(date))
        sums['holiday'] += amount
      else
        sums[ev.paidType] += amount
    })

    return sums
  }
}

export { Employee, HourlyEmployee, Student }
