import '../css'

import moment from 'moment'
import { Calendar, defaultCalendarOptions } from './calendar'

const appendEvents = function(el, events) {
  events = events.map(function(e) {
    e['startDate'] = moment(e['startDate']).toDate()
    e['endDate']   = moment(e['endDate']  ).toDate()
    return e
  })

  var data = $(el).data('calendar').getDataSource() || []
  $(el).data('calendar').setDataSource(data.concat(events))
}

$(function() {
  $('#legend .day').popover({
    container: 'body',
    trigger: 'hover',
    html: true
  })

  $('#request_off_campus').daterangepicker({
    locale: {
      format: 'YYYY-MM-DD',
      separator: ' — '
    }
  })

  // Initialize calendars
  $('#calendar, #review-calendar').calendar(defaultCalendarOptions)

  let calEl = $('#request-calendar')
  if (calEl.length > 0) {
    $('#intl-travel-modal').modal({ show: false })
    let calendar = new Calendar()
    calEl.calendar(calendar.requestCalendarOptions())
  }

  // AJAX requests for calendar events
  calEl = $('.calendar[data-calendar-url]')
  if (calEl.length > 0) {
    let options = calEl.data('calendar').options
    let eventsURL = calEl.attr('data-calendar-url')
    let holidaysURL = '/holidays'

    let years = [options.minDate, options.maxDate].map(date => date.getUTCFullYear()).join('-')
    eventsURL += (eventsURL.match(/\?/) ? '&' : '?') + `year=${years}`
    holidaysURL += (holidaysURL.match(/\?/) ? '&' : '?') + `year=${years}`;

    [eventsURL, holidaysURL].forEach(function(url) {
      $.ajax({
        url: url,
        method: 'GET',
        dataType: 'script'
      })
    })
  }
})

$(document).on('click', '#deny-link', function(e) {
  e.preventDefault()
  $('#denial-reason').removeClass('hidden')
})

// Match "off campus" dates to actual dates requested
$(document).on('change', '#request_meta', function() {
  let picker = $('#request_off_campus').data('daterangepicker')
  if (!picker) return

  let dates = []
  $.each(JSON.parse($(this).val()), (date) => dates.push(date))
  dates = dates.sort()
  if (dates.length == 0) return

  picker.setStartDate(dates[0])
  picker.setEndDate(dates[dates.length-1])
})

$(document).on('change', 'input[name="request[needs_correction]"]', function() {
  $('#correction-field').toggleClass('hidden', $('input[name="request[needs_correction]"]:checked').val() == 'false')
})

$(document).on('click', 'button.intl-travel', function() {
  $(`#request_international_feast_${$(this).hasClass('btn-success')}`)[0].click()
})

// Events for home page calendar
$(document).on('clickDay', '#calendar', function(e) {
  if (e.events.length == 0) return
  window.location.href = e.events.find(e => e.url).url
})

$(document).on('renderEnd', '.calendar', function() {
  if (!$(this).data('loaded'))
    $('.calendar .months-container').css({ visibility: 'hidden' })
})

// Add events from AJAX calls
$(document).on('calendar:index', '.calendar', function(e) {
  appendEvents(this, e.detail.events)
  $(this).data('eventsLoaded', true).trigger('calendar:ajax-complete')
})

$(document).on('calendar:holidays', '.calendar', function(e) {
  appendEvents(this, e.detail.events)
  $(this).data('holidaysLoaded', true).trigger('calendar:ajax-complete')
})

$(document).on('calendar:show', '.calendar', function(e) {
  appendEvents(this, e.detail.events)
  $(this).data('calendar').setYear(e.detail.year)
  $(this).data('eventsLoaded', true).trigger('calendar:ajax-complete')
})

$(document).on('calendar:ajax-complete', '.calendar', function() {
  if ($(this).data('eventsLoaded') && $(this).data('holidaysLoaded')) {
    $('.calendar').data('loaded', true)
    $('.calendar .months-container').css({ visibility: 'visible' })
  }
})
