import moment from 'moment'
import { Employee, HourlyEmployee, Student } from './person'

const calendarRenderer = function(el, date, events) {
  let descriptions = []
  let day = el.hasClass('day-content') ? el.parent() : el
  events.forEach(function(ev) {
    let request = 'approved' in ev
    // Consolidate descriptions for popover
    if (ev.description) {
      let desc = ev.description
      if (ev.employee)             desc = `${ev.employee}: ${desc}`
      if (request && !ev.approved) desc = `(pending) ${desc}`
      if (request)                 desc = `<b>${desc}</b>`

      descriptions.push(desc)
    }

    // Set event type classes
    day.addClass(ev.dayType).addClass(ev.paidType)
    if (request) {
      if (ev.approved) {
        if (day.children('i, svg').length == 0) day.prepend('<i class="fas fa-check"></i>')
      } else {
        day.addClass('pending')
      }
    }

    // Magic: remove the custom class and force a reflow to keep the "new" CSS animations in sync
    let klass
    if (ev.current) klass = 'current'
    if (ev.new)     klass = 'new'
    day.removeClass(klass)
    day.length > 0 && day[0].offsetWidth
    day.addClass(klass)
  })

  if (descriptions.length > 0) {
    day.popover({
      trigger: 'hover',
      container: 'body',
      content: descriptions.join('<br>'),
      html: true
    })
  }
}

const defaultCalendarOptions = {
  style: 'custom',
  minDate: moment().add(-1, 'year').startOf('year').toDate(),
  maxDate: moment().add(1, 'year').endOf('year').toDate(),
  customDataSourceRenderer: calendarRenderer
}

class Calendar {
  constructor() {
    this.menu = $('.calendar-context-menu')
    this.student = $('#request-calendar').attr('data-student') == 'true'
    this.hourly = $('#request-calendar').attr('data-hourly') == 'true'
    this.admin = $('#request-calendar').attr('data-admin') == 'true'
    this.oldEvents = {}
    this.newEvents = {}
    this.holidays = []
    this.feastTravel = []
    this.fallBreak = []

    let sunday = moment().add(-1, 'year').startOf('year').startOf('week')
    let endYear = moment().year() + 3

    while (sunday.year() < endYear) {
      this.holidays.push(sunday.format('YYYY-MM-DD'))
      this.holidays.push(sunday.add(6, 'days').format('YYYY-MM-DD'))
      sunday.add(1, 'day')
    }

    // Hide the context menu unless user right-clicks on day
    $(document).on('click', e => {
      if (!($(e.target).is('.day-content') && e.button == 1))
        this.menu.hide()
    })

    // Context menu options
    this.menu.children('.toggle-day').on('click', () => {
      let date = this.menu.data('date')
      this.newEvents[date] = this.person().toggleDay(date)
      this.render()
    })

    this.menu.children('.toggle-paid').on('click', () => {
      let date = this.menu.data('date')
      this.newEvents[date] = this.person().togglePaid(date)
      this.render()
    })

    // Reset request
    $(document).on('click', '#clear-days', (e) => {
      e.preventDefault()
      $('#clear-days').trigger('blur')
      this.newEvents = {}
      this.render()
    })

    $(document).on('click', '#feast-travel input', () => this.render())
  }

  person() {
    if (this.student)
      return new Student(this.admin, $.extend({}, this.oldEvents, this.newEvents), this.holidays, this.feastTravel, this.fallBreak)
    else if (this.hourly)
      return new HourlyEmployee(this.admin, $.extend({}, this.oldEvents, this.newEvents), this.holidays)
    else
      return new Employee(this.admin, $.extend({}, this.oldEvents, this.newEvents), this.holidays, this.feastTravel)
  }

  enumerateDays(ev) {
    let days = []
    let date = moment(ev.startDate)
    let endDate = moment(ev.endDate).add(1, 'day')
    while (!date.isSame(endDate, 'day')) {
      days.push(date.format('YYYY-MM-DD'))
      date.add(1, 'day')
    }

    return days
  }

  renderEvents(events, isNew) {
    $.each(events, function(date, ev) {
      ev.new = !!isNew
      calendarRenderer($(`.day[data-date="${date}"]`), null, [ev])
    })
  }

  render() {
    $('td.day').removeClass('half-day-start half-day-end full-day paid unpaid new')
    $('#request_meta').val(JSON.stringify(this.newEvents)).trigger('change')
    this.renderEvents(this.oldEvents)
    this.renderEvents(this.newEvents, true)

    let hideFeastTravel = this.student || $(this.feastTravel).filter(Object.keys(this.newEvents)).length == 0
    $('#feast-travel').toggleClass('hidden', hideFeastTravel)

    let calData = $('#request-calendar').data('calendar')
    let year = (calData != null && calData.getYear()) || moment().year()
    $('#days-selected .text').text(this.person().description(this.newEvents))
    $('#days-selected .remaining').html(this.person().remaining(year))

    if (!hideFeastTravel && $('input[name="request[international_feast]"]:checked').val() == null)
      $('#intl-travel-modal').modal('show')
  }

  requestCalendarOptions() {
    return $.extend({}, defaultCalendarOptions, {
      enableContextMenu: true,
      minDate: this.person().minDate.toDate(),
      maxDate: this.person().maxDate.toDate(),
      clickDay: (e) => {
        this.menu.hide()

        let date = moment(e.date).format('YYYY-MM-DD')
        if (this.oldEvents[date] != null) // Disable for previous requests
          return

        let updates = this.person().clickDay(date)
        Object.keys(this.oldEvents).forEach(d => delete updates[d]) // Don't update events from past requests

        Object.assign(this.newEvents, updates)
        if (updates[date] == null)
          delete this.newEvents[date]

        this.render()
      },
      dayContextMenu: (e) => {
        let date = moment(e.date).format('YYYY-MM-DD')
        if (this.newEvents[date] == null)
          return
        if ($(e.element).hasClass('past') && !this.admin)
          return

        $(e.element).popover('hide')
        this.menu.data('date', date)
        this.menu.find('.toggle-day .content').text(`Toggle ${this.newEvents[date].dayType == 'full-day' ? 'half' : 'full'} day`)

        this.menu.find('.toggle-paid .content')
          .text(`Toggle ${this.newEvents[date].paidType == 'paid' ? 'unpaid' : 'paid'}`)
          .toggleClass('text-muted', !this.person().canSwitchToPaid(date))

        this.menu.css({
          left: `${e.element.offset().left + 25}px`,
          top: `${e.element.offset().top + 25}px`
        }).show()
      },
      renderEnd: () => {
        $('.calendar-context-menu').hide()

        let parsed
        try { parsed = JSON.parse($('#request_meta').val()) }
        catch (e) {} // eslint-disable-line no-empty,no-unused-vars
        this.newEvents = parsed || {}

        let dataSources
        try { dataSources = $('#request-calendar').data('calendar').getDataSource() }
        catch (e) {} // eslint-disable-line no-empty,no-unused-vars
        dataSources = dataSources || []

        dataSources.forEach(ev => {
          if (ev.url != null)                      this.oldEvents[moment(ev.startDate).format('YYYY-MM-DD')] = ev
          if (ev.dayType == 'holiday')             this.holidays  = this.holidays.concat(this.enumerateDays(ev))
          if (ev.description == 'Fall Break (AC)') this.fallBreak = this.fallBreak.concat(this.enumerateDays(ev))

          if (ev.description == 'Feast of Tabernacles' && ev.dayType == 'holiday') {
            let date = moment(ev.startDate)
            // Feast travel time includes first weekday before the Feast
            do
              date.subtract(1, 'day')
            while ([0, 6].includes(date.day()))

            // Add the first travel day
            this.feastTravel.push(date.format('YYYY-MM-DD'))

            // Add all following days through the first weekday after the Feast
            do {
              date.add(1, 'day')
              this.feastTravel.push(date.format('YYYY-MM-DD'))
            } while (date.isSameOrBefore(ev.endDate) || [0, 6].includes(date.day()))
          }
        })

        this.render()
      },
      customDayRenderer: function(el, date) {
        let parent = el.parent()
        let d = moment(date)

        parent.attr('data-date', d.format('YYYY-MM-DD'))
        if ([0, 6].includes(d.day()))                         parent.addClass('holiday')
        if (d.isBefore(moment().startOf('day')))              parent.addClass('past')
        if (d.startOf('day').isSame(moment().startOf('day'))) parent.addClass('today')
      }
    })
  }
}

export { Calendar, defaultCalendarOptions }
